import {
  CarrierLogo,
  Column,
  Rating,
  Row,
  ShowWhen,
  TextBadge,
  Typography,
} from '@/components'
import { HighlightsContainer } from '@/components/molecules/ModalBase/styles'
import { ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import {
  doesSupportMonthlyPriceCalculation,
  getFinalPrices,
} from '@/modules/recommendation/price'
import { useAttributes } from '@/services/attributes'
import { useFeatureFlag } from '@/services/feature-flags'
import { theme } from '@/theme'
import { formatMoney, useIsMobile, useLanguage } from '@/utils'
import { AdaptiveTooltip, Button, Card, Carousel } from '@surein/ui'
import { CheckIcon, ChevronDown, Sparkles, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CountdownComponent } from './components/Countdown'
import { carrierRatingAmounts, useMessages } from './helpers'
import {
  BestOfferBadge,
  DiscountPlaceholder,
  Highlight,
  MobileCardFooter,
  Product,
  ProductCard,
  ProductCardsContainer,
  ProductFooter,
  ProductSection,
  ProductWrapper,
  ProductsContainer,
  RatingTooltipFootnote,
  RecommendationPriceSection,
  RecommendationPriceSection2,
  StarRating,
} from './styles'

const formatMoneyConfig = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

interface RecommendationProduct {
  amountInsured?: number
  amountInsuredUnlimited?: boolean
  carrierData: {
    logoUrl?: string
    claimHandlingRating?: number
    customerSatisfactionRating?: number
    responseTimeRating?: number
  }
  carrierId: string
  categoryId: string
  deductible?: number
  discountValue: number
  grossPrice: number
  offerTag?: string | null
  originalGrossPrice?: number
  overallRating?: number
  paymentPeriod?: string
  recommendationProductId: string
  // TODO: attributes
  parametersValues?: Record<string, any> // dynamicObjectPropType
  highlightedAttributes?: Record<string, boolean> // dynamicObjectPropType
  yesNoValues?: Record<string, 1 | 0> // dynamicObjectPropType
  contractCoverageRating?: number
}

interface ProductData {
  bestChoice: string
  locationId: string
  recommendationProducts: RecommendationProduct[]
}

interface ProductsProps {
  data: ProductData
  onChoose: (id: string) => void
  openDetails?: () => void
  countdownTargetDateInMs?: number
}

interface ProductBadgeProps {
  isBestChoice?: boolean
  offerTag: string
  overwriteBestChoiceText?: string
}

export const ProductBadge = ({
  isBestChoice,
  offerTag,
  overwriteBestChoiceText,
}: ProductBadgeProps) => {
  const { t } = useTranslation()
  return (
    <BestOfferBadge isBestChoice={isBestChoice}>
      {isBestChoice ? <Sparkles size={16} /> : null}
      <Typography variant="footnote">
        {isBestChoice && overwriteBestChoiceText
          ? overwriteBestChoiceText
          : t(offerTag)}
      </Typography>
    </BestOfferBadge>
  )
}

export const Products = ({
  data,
  onChoose,
  openDetails,
  countdownTargetDateInMs,
}: ProductsProps) => {
  const { isMobile } = useIsMobile()
  const { t } = useTranslation()

  return (
    <ProductSection>
      <ProductCardsContainer>
        <ProductsContainer
          columns={isMobile ? 1 : data?.recommendationProducts.length}
        >
          {isMobile ? (
            <Carousel startIndex={1}>
              {data?.recommendationProducts.map((item, index) => (
                <ProductCard
                  key={item.recommendationProductId}
                  variant={index === 1 ? 'elevated' : 'default'}
                >
                  <ProductWrapper>
                    <ProductItem
                      data={item}
                      isBestChoice={
                        data.bestChoice === item.recommendationProductId
                      }
                      onChoose={onChoose}
                      openDetails={openDetails}
                      countdownTargetDateInMs={countdownTargetDateInMs}
                    />
                  </ProductWrapper>
                </ProductCard>
              ))}
            </Carousel>
          ) : (
            data?.recommendationProducts.map((item, index) => (
              <Card
                key={item.recommendationProductId}
                variant={index === 1 ? 'elevated' : 'default'}
              >
                <ProductWrapper>
                  <ProductItem
                    data={item}
                    isBestChoice={
                      data.bestChoice === item.recommendationProductId
                    }
                    onChoose={onChoose}
                    openDetails={openDetails}
                    countdownTargetDateInMs={countdownTargetDateInMs}
                  />
                </ProductWrapper>
              </Card>
            ))
          )}
        </ProductsContainer>
      </ProductCardsContainer>
      <Typography bold color={ThemeColor.danger1} variant="badgeText">
        {t('offerValidFor')}
      </Typography>
    </ProductSection>
  )
}

const HighlightItem = ({
  isChecked,
  attributeName,
  parameterText = '',
}: {
  isChecked: boolean
  attributeName: string
  parameterText?: string
}) => {
  const icon = isChecked ? (
    <CheckIcon color={theme.color[ThemeColor.success1]} size="16px" />
  ) : (
    <X color={theme.color[ThemeColor.danger1]} size="16px" />
  )
  return (
    <Highlight>
      {icon}
      <Typography color={ThemeColor.b100} variant="footnote">
        {attributeName}
        {parameterText && `: ${parameterText}`}
      </Typography>
    </Highlight>
  )
}

const RatingTooltipContent = ({
  ratings,
}: {
  ratings: {
    contractCoverageRating?: number
    claimHandlingRating?: number
    customerSatisfactionRating?: number
    responseTimeRating?: number
  }
}) => {
  const { t } = useTranslation()
  return (
    <Column alignItems="start" gap="24px" width="400px">
      <Column alignItems="start" gap="8px">
        {Object.entries(ratings).map(
          ([key, value]) =>
            value && (
              <>
                <Typography color={ThemeColor.b100} variant="p2Body">
                  {t(`ratingTitles.${key}`)}
                </Typography>
                <Rating reverse value={value} />
              </>
            ),
        )}
      </Column>
      <RatingTooltipFootnote color={ThemeColor.b100} variant="p2Body">
        * {t('basedOnExperienceWithCarrier')}
      </RatingTooltipFootnote>
    </Column>
  )
}

interface ProductItemProps {
  data: RecommendationProduct
  isBestChoice?: boolean
  onChoose: (recommendationProductId: string) => void
  openDetails?: () => void
  countdownTargetDateInMs?: number
}

const ProductItem = ({
  data,
  onChoose,
  isBestChoice = false,
  openDetails,
  countdownTargetDateInMs,
}: ProductItemProps) => {
  const { isMobile } = useIsMobile()
  const language = useLanguage()
  const { t } = useTranslation()
  const analytics = useSegment()
  const messages = useMessages(data.categoryId)
  const {
    isLoading: isAttributesLoading,
    data: attributesData,
  } = useAttributes()

  const yesNo = data?.yesNoValues || {}
  const highlights = Object.fromEntries(
    Object.entries(data.highlightedAttributes || {})
      .filter(([key, value]) => value && key in yesNo)
      .map(([key]) => [key, Boolean(yesNo[key])] as [string, boolean])
      .sort(([_k1, a], [_k2, b]) => Number(b) - Number(a)),
  )

  const ratings = {
    contractCoverageRating: data.contractCoverageRating,
    claimHandlingRating: data.carrierData.claimHandlingRating,
    customerSatisfactionRating: data.carrierData.customerSatisfactionRating,
    responseTimeRating: data.carrierData.responseTimeRating,
  }
  const areRatingsEmpty = Object.values(ratings).every((value) => !value)

  const { data: monthlyPriceFeatureFlag } = useFeatureFlag(
    'offer-price-monthly',
  )

  if (isAttributesLoading || !attributesData) {
    return null
  }

  const { finalPrice, discountedPrice } = getFinalPrices(
    data.grossPrice,
    data.discountValue,
    data.paymentPeriod ?? 'yearly',
    monthlyPriceFeatureFlag?.state,
  )

  const parameters = data?.parametersValues?.[language] || {}

  const handleDetailsButtonClick = async () => {
    await analytics.track('click_details_product')
    if (openDetails) {
      openDetails()
    }
  }

  return (
    <Product data-testid="product-item">
      <Column gap="16px">
        <Row
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          wrap="wrap"
        >
          <CarrierLogo name={data.carrierData.logoUrl} />
          {data.offerTag && (
            <ProductBadge
              isBestChoice={isBestChoice}
              offerTag={data.offerTag}
            />
          )}
        </Row>
        <Typography variant="p2Body">{messages.pricePaidToCarrier}</Typography>
        <Column gap="16px">
          <Column>
            <ShowWhen
              fallback={() => <DiscountPlaceholder />}
              when={Boolean(data.discountValue)}
            >
              <RecommendationPriceSection>
                <Typography color={ThemeColor.b50} variant="p1Body">
                  {formatMoney(discountedPrice)}
                </Typography>
              </RecommendationPriceSection>
            </ShowWhen>
            <RecommendationPriceSection>
              <RecommendationPriceSection2>
                <Typography bold color={ThemeColor.b100} variant="h4">
                  {formatMoney(finalPrice) || '----'}
                </Typography>
                <Typography color={ThemeColor.b50} variant="badgeText">
                  /{' '}
                  {monthlyPriceFeatureFlag?.state &&
                  doesSupportMonthlyPriceCalculation(data.paymentPeriod)
                    ? t('paymentPeriodOptions.monthly')
                    : t(`paymentPeriodOptions.${data.paymentPeriod}`)}
                </Typography>
              </RecommendationPriceSection2>
            </RecommendationPriceSection>
            {monthlyPriceFeatureFlag?.state &&
              doesSupportMonthlyPriceCalculation(data.paymentPeriod) &&
              data.paymentPeriod !== 'monthly' && (
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t(`paid.${data.paymentPeriod}`)}
                </Typography>
              )}
          </Column>
          <ShowWhen
            fallback={() => <DiscountPlaceholder />}
            when={Boolean(data.discountValue)}
          >
            <Row alignItems="center" gap="8px">
              <TextBadge
                backgroundColor={ThemeColor.success3}
                size="md"
                text={`-${data.discountValue}% ${messages.sureinDiscount}`}
                textColor={ThemeColor.success1}
              />
              {countdownTargetDateInMs && (
                <CountdownComponent
                  targetDateInMs={countdownTargetDateInMs}
                  withTooltip
                />
              )}
            </Row>
          </ShowWhen>
        </Column>
        <ShowWhen when={!isMobile}>
          {data.offerTag === 'best_choice' ? (
            <Button
              onClick={() => onChoose(data.recommendationProductId)}
              shape="square"
              variant="primary"
            >
              {messages.getCoverage}
            </Button>
          ) : (
            <Button
              onClick={() => onChoose(data.recommendationProductId)}
              shape="square"
              variant="muted"
            >
              {messages.getCoverage}
            </Button>
          )}
        </ShowWhen>
        <HighlightsContainer>
          <Typography color={ThemeColor.glass500} variant="footnote">
            {messages.mainAdvantages}:
          </Typography>
          <Column gap="8px">
            <HighlightItem
              attributeName={`${messages.deductible} ${formatMoney(
                data.deductible,
                'euro',
                formatMoneyConfig,
              )}`}
              isChecked={true}
            />
            {Object.entries(highlights).map(([key, value]) => (
              <HighlightItem
                key={key}
                attributeName={attributesData.getAttributeLabel(key)}
                isChecked={value}
                parameterText={parameters[key]}
              />
            ))}
          </Column>
        </HighlightsContainer>
      </Column>
      <Column gap="32px">
        <Row gap="16px" justifyContent="space-between">
          <Column gap="4px">
            <Typography color={ThemeColor.glass500} variant="footnote">
              {messages.coverage}
            </Typography>
            <Typography variant="inter">
              {data.amountInsuredUnlimited
                ? messages.unlimited
                : formatMoney(data.amountInsured, 'euro', formatMoneyConfig)}
            </Typography>
          </Column>
          <Column gap="4px">
            <Typography color={ThemeColor.glass500} variant="footnote">
              {messages.deductible}
            </Typography>
            <Typography
              color={
                data.deductible === 0 ? ThemeColor.success1 : ThemeColor.b100
              }
              variant="inter"
            >
              {formatMoney(data.deductible, 'euro', formatMoneyConfig)}
            </Typography>
          </Column>
        </Row>
        <MobileCardFooter>
          <ShowWhen when={isMobile}>
            {data.offerTag === 'best_choice' ? (
              <Button
                onClick={() => onChoose(data.recommendationProductId)}
                shape="square"
                size="sm"
                variant="primary"
              >
                {messages.getCoverage}
              </Button>
            ) : (
              <Button
                onClick={() => onChoose(data.recommendationProductId)}
                shape="square"
                size="sm"
                variant="muted"
              >
                {messages.getCoverage}
              </Button>
            )}
          </ShowWhen>
          <ProductFooter>
            {openDetails ? (
              <Button
                size="xs"
                variant="muted"
                onClick={handleDetailsButtonClick}
              >
                {messages.details}
              </Button>
            ) : (
              <a href="#details">
                <Button
                  size="xs"
                  variant="muted"
                  onClick={handleDetailsButtonClick}
                >
                  {messages.details}
                </Button>
              </a>
            )}
            <StarRating>
              {!areRatingsEmpty ? (
                <AdaptiveTooltip
                  // FIXME: AdaptiveTooltip type is marked as string only
                  // @ts-expect-error - we should fix AdaptiveTooltip types
                  content={<RatingTooltipContent ratings={ratings} />}
                >
                  <Row alignItems="center" gap="4px">
                    <Rating
                      iconSize={10}
                      ratingAmount={
                        carrierRatingAmounts[
                          data.carrierId as keyof typeof carrierRatingAmounts
                        ]
                      }
                      reverse
                      value={data.overallRating}
                    />
                    <ChevronDown size={12} />
                  </Row>
                </AdaptiveTooltip>
              ) : (
                <Rating
                  iconSize={10}
                  ratingAmount={
                    carrierRatingAmounts[
                      data.carrierId as keyof typeof carrierRatingAmounts
                    ]
                  }
                  reverse
                  value={data.overallRating}
                />
              )}
            </StarRating>
          </ProductFooter>
        </MobileCardFooter>
      </Column>
    </Product>
  )
}
